@import "../node_modules/@fontsource/rubik-glitch/index.css";
@import "../node_modules/@fontsource/abeezee/index.css";

@import "video.js/dist/video-js.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rlb-orange: #fe5c38;
  --rlb-green: #003e4c;
  --rlb-yellow: #ffc94c;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  @apply tracking-wide;
}

.rlb-container {
  @apply container mx-auto px-4;
}

.rlb-container-with-padding {
  @apply container mx-auto px-8;
}

.alignfull {
  max-width: 100vw;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.select-invalid:invalid {
  color: #9ca3af;
}

.grecaptcha-badge {
  visibility: hidden;
}

button.MuiButton-containedSecondary {
  background-color: var(--rlb-green);
}
button.MuiButton-containedPrimary {
  background-color: var(--rlb-orange);
}
button.MuiButton-containedSuccess {
  background-color: #2e7d32;
}
